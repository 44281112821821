html,body,p,ul,h1 {
   margin:0;
   padding:0;
}

.horizontalFlexBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.verticalFlexBox {
  display: flex;
  flex-direction: column;
}

button {
  background: #F00F00;
  border: 1px solid #F00F00;
  border-radius: 6px;
  width: 256px;
  height: 48px;
  color: #FFFFFF;
  font-family: "futura-pt";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  cursor: pointer;
}

input {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  height: 48px;
  padding-left: 8px;
  margin-right: 8px;
  font-family: "proxima-nova";
}

input[type='radio'] {
  height: 0px;
}

input[type='radio']:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #BDBDBD;
}

input[type='radio']:checked:after {
  width: 14px;
  height: 14px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #F00F00;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 3px solid white;
  box-shadow: 0 0 0 2pt #BDBDBD
}

input[type='checkbox'] {
  height: 0px;
}

input[type='checkbox']:after {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: white;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid #BDBDBD;
}

input[type='checkbox']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 4px;
  top: -1px;
  left: 0px;
  position: relative;
  background-color: #F00F00;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 3px solid white;
  box-shadow: 0 0 0 2pt #BDBDBD
}

input[type=text] {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  height: 48px;
  padding-left: 8px;
  margin-right: 8px;
  font-family: "proxima-nova";
}

input[type=date] {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  height: 48px;
  padding-left: 8px;
  margin-right: 8px;
  font-family: "proxima-nova";
  padding-right: 8px;
}

textarea {
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  resize: vertical;
  height: 120px;
  padding: 10px;
  margin-top: 9px;
  font-family: "proxima-nova";
}

label {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #4F4F4F;
  padding-top: 40px;
}