.overallModule {
  margin-top: 90px;
}

.overallModuleMobile {
  padding: 20px 0 20px 24px;
  background-color: #F9F9F9;
}

.destinationGridMobile {
  display: grid; 
  margin-top: 20px;
  grid-template-columns: 1fr;
}

.titleText {
  color: #F00F00;
  font-size: 20px;
  font-weight: 700;
  font-family: "futura-pt";
}

.destinationGrid {
  display: grid; 
  margin-top: 31px;
  margin-right: 32px;
  grid-template-columns: 1fr 1fr 1fr;
}

.overallCard {
  width: 370px;
  height: auto;
  border-radius: 6px;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
  margin-right: 32px;
  margin-top: 25px;
  cursor: pointer;
}

.overallCardMobile {
  width: 85vw;
  height: auto;
  border-radius: 6px;
  border: 1px solid #E0E0E0;
  box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
  cursor: pointer;
  margin-top: 20px;
}

.heroImage {
  width: 100%;
  height: 216px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.textRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
}

.listingName {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}

.listingCost {
  font-family: "futura-pt";
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
}