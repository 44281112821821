.fullPage {
  max-width: 1186px;
  padding: 25px 9vw 25px 9vw;
  margin: 0 auto;
}

.cityName {
  color: #828282;
  font-family: "proxima-nova";
  font-size: 14px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
}

.listingName {
  font-family: "futura-pt";
  font-size: 24px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 28px;
}

.heroImage {
  width: 50vw;
  max-width: 700px;
  max-height: 462px;
  height: 33vw;
  border-radius: 4px;
  padding-bottom: 40px;
}

.listingDescription {
  width: 50vw;
  max-width: 700px;
  font-family: "proxima-nova";
  font-size: 16px;
  padding-bottom: 32px;
  white-space: pre-wrap;
}

.titleText {
  color: #F00F00;
  font-size: 20px;
  font-weight: 700;
  font-family: "futura-pt";
}

.amenityText {
  font-size: 16px;
  font-weight: 400;
  font-family: "proxima-nova";
  margin-left: 10px;
}

.amenityView {
  width: 170px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #F2F2F2;
  margin-right: 30px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.amenitiesGrid {
  padding-top: 30px;
  padding-bottom: 80px;
  width: 50vw;
  max-width: 700px;
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr;
},

.amenitiesGridMobile {
  padding-top: 30px;
  padding-bottom: 80px;
  width: 770px;
  display: grid; 
  grid-template-columns: 1fr;
},

.amenityImage {
  width: 10px;
  margin-left: 10px;
  margin-top: 10px;
}

.card {
  width: 25vw;
  max-width: 397px;
  min-height: 200px;
  box-shadow: 0px 1px 1px 1px rgba(0,0,0,0.1);
  border-color: lightgrey;
  border-width: 1px;
  border-radius: 6px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cityName2 {
  font-weight: 700;
  font-size: 18px;
  font-family: "futura-pt";
  padding-bottom: 20px;
}

.contentRow {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.requestTitle {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 30px;
  color: #828282;
  padding-bottom: 6px;
}

.requestTitle2 {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 30px;
  color: #828282;
  padding-bottom: 6px;
}

.requestBody {
  font-family: "futura-pt";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #232323;
  padding-bottom: 16px;
}

.hairline {
  border: 1px solid #E0E0E0;
  width: 100%;
  margin: 16px 0 16px 0;
}

.mobileView {
  padding: 6%;
}

.heroImageMobile {
  width: 100%;
  border-radius: 4px;
  padding-bottom: 11px;
}

.listingDescriptionMobile {
  font-family: "proxima-nova";
  font-size: 16px;
  padding-bottom: 3vh;
  white-space: pre-wrap;
}
