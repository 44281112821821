.horizontalBox {
	display: flex;
	flex-direction: column;
	margin: 60px;
	align-items: center;
}

.titleText {
	font-family: "futura-pt";
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
	text-align: center;
}

.subtitleText {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	padding-top: 12px;
}

.inputFieldCorrect {
	border: 1px solid black;
	width: 25%;
	text-align: center;
	margin-top: 48px;
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
}

.inputFieldIncorrect {
	border: 1px solid red;
	width: 25%;
	text-align: center;
	margin-top: 48px;
	font-size: 24px;
	font-weight: bold;
	text-transform: uppercase;
}

.incorrectCode {
	font-family: "proxima-nova";
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 24px;
	color: #F00F00;
}

.button {
  width: 310px;
  height: 48px;
  background-color: #F00F00;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 62px;
}

.buttonText {
  text-align: center;
  color: white;
  font-size: 18px;
  font-weight: 500;
  font-family: "futura-pt";
  padding-top: 12px;
}